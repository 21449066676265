import React from "react";
import "./style.scss";

import { Container, Card, Row, Col } from "react-bootstrap";

function index() {
  return (
    <div className="page_container">
      <Container
        fluid
        className="d-flex flex-row justify-content-center align-items-center w-100"
      >
        <Row className="d-flex flex-row justify-content-center align-items-center w-100">
          <Col
            xxl={3}
            xl={3}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className="d-flex flex-row justify-content-center align-items-center p-2"
          >
            <Card className="card_box">
              <Card.Img
                variant="top"
                className="card_image"
                src="https://picsum.photos/200/300"
              />
              <div className="card_price">
                <span>TZS 10,000</span>
                <span>-</span>
                <span>TZS 10,000</span>
              </div>
              <Card.Body>
                <span className="card_subtitle">Dar es Salaaam</span>
                <Card.Title className="card_title">Event Name</Card.Title>
                <Card.Text className="card_paragraph">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </Card.Text>
              </Card.Body>
              <div className="d-flex flex-row justify-content-between border-top p-3">
                <div className="card_time">
                  <i class="bi bi-clock-history"></i>
                  <span className="ml-2">3 days 2 nights</span>
                </div>
                <div className="card_rating">
                  <i class="rating_icon bi bi-stars"></i>
                  <span>4.5</span>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default index;
